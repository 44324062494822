import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ImageRenderer from "../components/atoms/ImageRenderer"
import { useLocation } from "@reach/router"

const UserEmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState(null) // Holds the success or error message
  const location = useLocation() // Get the current location
  const searchParams = new URLSearchParams(location.search) // Parse query parameters
  const token = searchParams.get("token")
  const isMoblie = searchParams.get("isMoblie")

  let redirect_url = "";

  if(isMoblie === "true"){
    redirect_url = `synkli://auth/OtpScreen`
  }else{
    redirect_url = `https://partners.synkli.dev/signup-email/link-verify`
  }

  useEffect(() => {
    if (!token) {
      setVerificationStatus("error") // If token is missing, set status to error
      return
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(
          process.env.GATSBY_EMAIL_VERIFY_API_ENDPOINT,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token,
            }),
          }
        )

        const result = await response.json()
        if (response.ok) {
          setVerificationStatus("success") // Set status to success if API call was successful
        } else {
          setVerificationStatus("error") // Set status to error if the API response was an error
        }
      } catch (error) {
        setVerificationStatus("error") // Handle any network or unexpected errors
      }
    }

    verifyEmail() // Call the function when the component mounts
  }, [token]) // Add token as a dependency to ensure the effect runs if it changes

  return (
    <>
      <Seo title="User Email Verification" />
      <div className="bg-[#FAF9F6] min-h-[100vh] px-[20px]">
        <div className="synkli-section--horizontal-space flex flex-wrap items-center justify-between pt-6 sm:pt-10 pb-4 !px-0">
          <Link to="/">
            <div className="w-[110px]">
              <ImageRenderer
                img={`synkli-logo-transparent`}
                alt={"synkli-logo-transparent"}
              />
            </div>
          </Link>
        </div>
        <div className="bg-white min-h-[70vh] rounded-[18px] text-center border-b-[20px] border-[#101828] max-w-[950px] mx-auto mt-5 py-14 px-5">
          {verificationStatus === "success" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`synkli-user-email-verification-success`}
                  alt={`synkli-user-email-verification-success`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Email Verified Successfully!
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">Thank you!</span> Your email
                has been successfully verified. You can now return to your
                application Synkli.
              </p>
              <a
                href={redirect_url}
                className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-2 transition duration-300 primary-btn inline-block"
                rel="noopener noreferrer"
              >
                Return to Your Application
              </a>
            </>
          ) : verificationStatus === "error" ? (
            <>
              <div className="max-w-[110px] mx-auto">
                <ImageRenderer
                  img={`synkli-user-email-verification-failed`}
                  alt={`synkli-user-email-verification-failed`}
                />
              </div>
              <h1 className="text-[22px] md:text-[24px] text-[#101828] font-[600] mt-10">
                Email Verification Failed
              </h1>
              <p className="text-[18px] text-[#101828] max-w-[800px] mx-auto mt-5 mb-8">
                <span className="text-[#B695F8]">We're sorry!</span> The link
                you used is either invalid or has expired. Please try verifying
                your email again or contact our support team for assistance.
              </p>
              <a
                href={redirect_url}
                className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-2 transition duration-300 primary-btn inline-block"
                rel="noopener noreferrer"
              >
                Return to Your Application
              </a>
            </>
          ) : (
            <>
              <div className="max-w-[140px] mx-auto mb-5">
                <ImageRenderer img={`synkli-loader`} alt={`synkli-loader`} />
              </div>
              <p className="text-[18px] text-[#101828]">
                Verifying your email...
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserEmailVerification
